import React from "react";
import {
  GridGenerator,
  HexGrid,
  Layout,
  Path,
  Hexagon,
  Text,
  Pattern,
  Hex,
} from "react-hexgrid";
import styled from "styled-components";
import { Component } from "react";
// import './App.css';

const StyledHexagon = styled(Hexagon)`
  fill: #4499a9;
  fill-opacity: 0.8;
  stroke: #bbbbbb;
  box-shadow: 5px;

  stroke-width: 0.1;
  font-size: 0.22em;
  fill-opacity: 0.8;
  transition: fill-opacity 0.5s;
  &:hover {
    fill: red;
    fill-opacity: 1;
  }
`;

const HexaCenterer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

function HexagonGrid7({ height, width, imgDatas, viewBox, flat = true }) {
  function buildHexagons(imgDatas) {
    const hexagons = imgDatas.map((data, index) => {
      const pos = data.position;
      const ref = `hexa${data.ref}`;
      const hexagon = (
        <a href={data.href}>
          <StyledHexagon q={pos[0]} r={pos[1]} s={pos[2]} fill={data.hexaref} />
        </a>
      );
      return hexagon;
    });
    return hexagons;
  }
  function buildPatterns(imgDatas) {
    return imgDatas.map((data, index) => {
      const link = data.link;
      return (
        <Pattern id={data.hexaref} link={link} key={data.hexaref} size={data.size} />
      );
    });
  }
  const hexagons = buildHexagons(imgDatas);
  const patterns = buildPatterns(imgDatas);
  return (
    <HexaCenterer>
      <HexGrid width={width} height={height} viewBox={viewBox}>
        {/* Grid with manually inserted hexagons */}
        <Layout
          rt
          size={{ x: 10, y: 10 }}
          flat={flat}
          spacing={1.1}
          origin={{ x: 0, y: 0 }}
        >
          {hexagons}
        </Layout>
        {patterns}
      </HexGrid>
    </HexaCenterer>
  );
}

function HexagonGrid2({ size, name, links }) {
  const width = size;
  const height = size / 2;
  const ratio = width / height;
  const name1 = `${name}-1`;
  const name2 = `${name}-2`;
  return (
    <HexaCenterer>
      <HexGrid
        width={width}
        height={height}
        viewBox={`-40 ${-20 / ratio} 60 ${60 / ratio}`}
      >
        {/* <HexGrid width={400} height={200} viewBox={`-30 -15 60 30`}> */}
        {/* Grid with manually inserted hexagons */}
        <Layout
          size={{ x: 10, y: 10 }}
          flat={true}
          spacing={1.1}
          origin={{ x: 0, y: 0 }}
        >
          <StyledHexagon q={0} r={0} s={0} fill={name1} />
          {/* Using pattern (defined below) to fill the hexagon */}
          <StyledHexagon q={-1} r={1} s={0} fill={name2} />
        </Layout>
        <Pattern id={name1} link={links[0]} />
        <Pattern id={name2} link={links[1]} />
      </HexGrid>
    </HexaCenterer>
  );
}

function HexagonGrid1({ size, name, link }) {
  return (
    <HexaCenterer>
      <HexGrid width={size} height={size / 2} viewBox={`-30 -10 60 20`}>
        {/* <HexGrid width={400} height={200} viewBox={`-30 -15 60 30`}> */}
        {/* Grid with manually inserted hexagons */}
        <Layout
          size={{ x: 10, y: 10 }}
          flat={false}
          spacing={1.1}
          origin={{ x: 0, y: 0 }}
        >
          <StyledHexagon q={0} r={0} s={0} fill={name} />
          {/* Using pattern (defined below) to fill the hexagon */}
        </Layout>
        <Pattern id={name} link={link} size={{ x: 10, y: 9 }} />
      </HexGrid>
    </HexaCenterer>
  );
}

export { HexagonGrid7, HexagonGrid2, HexagonGrid1 };
