import styled from "styled-components";
import { FaHeart, FaArrowUp } from "react-icons/fa";
import React, { useState, useRef, useEffect } from "react";
import TimeLineData from "../../content/timeline.json";

function parseDateFields(items) {
  const parsedItems = items.map((item) => {
    let parsedItem = { ...item };
    if (Object.hasOwn(item, "date")) {
      const parsedDate = parseStrToDate(item.date);
      parsedItem.date = parsedDate;
    }
    return parsedItem;
  });
  return parsedItems;
}

function parseStrToDate(dateStr) {
  var parts = dateStr.split("-");
  var mydate = new Date(parts[0], parts[1] - 1, parts[2]);
  return mydate;
}

const TimeLine = () => {
  let items = TimeLineData.items;
  items = parseDateFields(items);
  const sortedItems = items.sort((a, b) => b.date - a.date);
  var childItems = [];
  var refYear = Math.max(...items.map((item) => item.date.getFullYear()));
  for (let i = 0; i < sortedItems.length; i++) {
    let item = items[i];
    let currentYear = item.date.getFullYear();
    while (refYear > currentYear) {
      childItems.push(<YearItem year={refYear} />);
      refYear -= 1;
    }

    childItems.push(
      <TimeLineItem
        title={item.title}
        itemdate={item.date.toLocaleDateString("en-us", {
          year: "numeric",
          month: "short",
        })}
        description={item.description}
        id={item.id}
        imgSrc={item.imgSrc}
        key={i}
        index={i}
        readMoreHref={item.readMore}
      />
    );
  }
  const lastYear = sortedItems[sortedItems.length - 1].date.getFullYear();
  childItems.push(<YearItem year={lastYear} />);
  const years = items.map((item) => item.date.getFullYear());
  const minYear = Math.min(...years);
  const maxYear = Math.max(...years);
  return (
    <>
      <YearTags minYear={minYear} maxYear={maxYear} />
      <TimeLineWrapper>
        <TimelineBackbone />
        {childItems}
      </TimeLineWrapper>
    </>
  );
};

const YearItem = ({ year }) => {
  return (
    <YearItemWrapper id={`timelineYear${year}`}>
      <YearText>
        <p>{year}</p>
      </YearText>
    </YearItemWrapper>
  );
};

const YearItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;
  margin: 30px;
`;
const YearText = styled.div`
  font-size: 28px;
  border-radius: 50%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 140px;
  width: 140px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #666666; //${({ theme }) => theme.maincolor.color1};
  font-size: 28px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
`;

const YearTags = ({ minYear, maxYear }) => {
  let links = [];
  for (let year = minYear; year <= maxYear; year++) {
    let yearlink = (
      <YearLink index={year} href={`#timelineYear${year}`}>
        {year}
      </YearLink>
    );
    links.push(yearlink);
  }
  return <FlexContainer>{links}</FlexContainer>;
};
const YearLink = styled.a`
  padding: 20px;
  margin: 20px;
  font-size: 28px;
  text-decoration: none;
  background: ${({ theme }) => theme.maincolor.color1};
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  /* font-size: 17px; */
  font-weight: 400;
  transition: all 0.3s ease;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const TimeLineWrapper = styled.div`
  * {
    box-sizing: border-box;
  }
  max-width: 10000px;
  margin: 50px auto;
  position: relative;
  padding: 0 20px;
  @media screen and (max-width: 480px) {
    padding:0;
  }
`;

const VisibilityWrapper = styled.div`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  transition: opacity 2.9s ease-out, transform 2.9s ease-out;
  will-change: opacity;
  opacity: ${(props) => (props.isVisible ? 1.0 : 0.0)};
`;
const LabelBox = ({ title, itemdate, description, readMoreHref }) => {
  const [isVisible, setVisible] = useState(false);
  const domRef = React.useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // In your case there's only one element to observe:
      if (entries[0].isIntersecting) {
        // Not possible to set it back to false like this:
        setVisible(true);

        // No need to keep observing:
        observer.unobserve(domRef.current);
      }
    });
    observer.observe(domRef.current);
    return () => observer.disconnect();
  }, []);

  return (
    <VisibilityWrapper
      ref={domRef}
      style={{ position: "relative" }}
      isVisible={isVisible}
    >
      <Details>
        <Title>{title}</Title>
        <ItemDate>{itemdate}</ItemDate>
      </Details>
      <Description>{description}</Description>
      <DetailBottom>
        {readMoreHref && <MoreButton href={readMoreHref}> Read More</MoreButton>}
      </DetailBottom>
    </VisibilityWrapper>
  );
};

const TimeLineItem = ({ title, itemdate, description, imgSrc, index, id, readMoreHref }) => {
  const isLeft = index % 2;

  const children = (
    <LabelBox
      title={title}
      itemdate={itemdate}
      description={description}
      readMoreHref={readMoreHref}
    />
  );
  if (isLeft) {
    return (
      <LeftRow key={index} id={id}>
        <Section>
          <LeftIcon imgSrc={imgSrc} />
          {children}
        </Section>
      </LeftRow>
    );
  }
  return (
    <RightRow key={index} id={id}>
      <Section>
        <RightIcon imgSrc={imgSrc} />
        {children}
      </Section>
    </RightRow>
  );
};
const Description = styled.p`
  font-size: 24px;
  @media screen and (max-width: 1382px) {
    font-size: 18px;
  }
  color: #222222;
`;
const MoreButton = styled.a`
  text-decoration: none;
  background: ${({ theme }) => theme.maincolor.color1};
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  /* font-size: 17px; */
  font-weight: 400;
  transition: all 0.3s ease;
`;
const DetailBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a:hover {
    transform: scale(0.97);
  }
`;
const ItemDate = styled.span`
  color: #555555;
  font-size: 16pt;
  margin-left: 1em;
`;

const Title = styled.p`
  text-align: center;
  font-size: 25px;
  font-weight: 600;
`;
const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  @media screen and (max-width: 1382px) {
    flex-direction: column;
  }
`;

const Icon = styled.div`
  top: 15px;
  position: absolute;
  background: white;
  background-image: url("${(props) => props.imgSrc}");
  background-size: cover;
  height: 140px;
  width: 140px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: ${({ theme }) => theme.maincolor.color2};
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 1382px) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1em;
    position: static;
  }
`;
const LeftIcon = styled(Icon)`
  right: -190px;
  @media screen and (max-width: 1382px) {
    right: 0px;
  }
`;
const RightIcon = styled(Icon)`
  // add half of Icon.height.delta
  left: -190px;

  @media screen and (max-width: 1382px) {
    left: 0px;
  }
`;

const LeftRow = styled.div`
  display: flex;
  transform: scale(0.97);
  justify-content: flex-start;
`;
const RightRow = styled.div`
  display: flex;
  transform: scale(0.97);
  justify-content: flex-end;
  @media screen and (max-width: 1382px) {
    display: block;
  }
`;

const Section = styled.section`
  background: #fff;
  border-radius: 5px;
  width: calc(50% - 120px);
  padding: 30px;
  position: relative;
  @media screen and (max-width: 1382px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    padding:0;
  }
`;

const BackToTopLink = styled.a`
  position: absolute;
  background: #f2f2f2;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: ${({ theme }) => theme.maincolor.color1};
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
  bottom: -30px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
`;

const BackToTopButton = () => {
  return (
    <BackToTopLink href="#">
      <FaArrowUp />
    </BackToTopLink>
  );
};
const TimelineBackbone = () => {
  return (
    <GreyCentralLine>
      <BackToTopButton />
    </GreyCentralLine>
  );
};
const GreyCentralLine = styled.div`
  position: absolute;
  height: 100%;
  width: 4px;
  background: #999999;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  @media screen and (max-width: 1382px) {
    opacity: 0;
  }
`;

const Wrapper = styled.div``;
export { TimeLine };
