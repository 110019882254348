import * as React from "react";
import styled from "styled-components";
const CenteredH1 = styled.h1`
  text-align: center;
`;
const ExpeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const ExpeSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: min(30em,90%);
`;

export { CenteredH1, ExpeWrapper, ExpeSectionWrapper };
