import * as React from "react";
import Layout from "../components/layout";
import { TimeLine } from "../components/timeline";
import PageHeading from "../components/page_heading";
import {
  HexagonGrid7,
} from "../components/hexagon";
import { CenteredH1, ExpeWrapper, ExpeSectionWrapper } from "../components/pages/experience";

const AboutPage = () => {
  const hexSize = 400;
  const schoolImgDatas = [
    {
      position: [0, 0, 0],
      link: "/experience/new_epfl.png",
      href: "#epfl",
      hexaref: "hexaepfl",
      size: { x: 10, y: 9 },
    },
    {
      position: [-1, 1, 0],
      link: "/experience/eth_new.png",
      href: "#eth",
      hexaref: "hexaeth",
      size: { x: 10, y: 10 },
    },
  ];
  const packageImgDatas = [
    {
      position: [0, 0, 0],
      link: "/packages/mt2gf_copy.png",
      href: "https://pypi.org/project/mt2gf/",
      hexaref: "hexamt2gf",
      size: { x: 10, y: 10 },
    },
    {
      position: [-1, 1, 0],
      link: "/packages/torchlurk.png",
      href: "https://pypi.org/project/torchlurk/",
      hexaref: "hexatorchlurk",
      size: { x: 10, y: 9 },
    },
  ];
  const jobImgDatas = [
    {
      position: [0, 0, 0],
      link: "/experience/cisco.png",
      href: "#cisco",
      hexaref: "hexacisco",
      size: { x: 10, y: 9 },
    },

    {
      position: [0, -1, 1],
      link: "/experience/mathis.png",
      href: "#mathis",
      hexaref: "hexamathis",
      size: { x: 10, y: 9 },
    },
    {
      position: [0, 1, -1],
      link: "/experience/techno_deplancke.png",
      href: "#deplancke",
      hexaref: "hexadeplanckes",
      size: { x: 10, y: 9 },
    },
    { position: [1, -1, 0], link: "/experience/topo.png", href: "#topo",hexaref:"hexatopo" },
    {
      position: [1, 0, -1],
      link: "/experience/visium.jpg",
      href: "#visium",
      hexaref:"hexavisium",
      size: { x: 10, y: 9 },
    },
    {
      position: [-1, 1, 0],
      link: "/experience/bfh.png",
      href: "#bfh",
      hexaref:"hexabfh",
      size: { x: 10, y: 9 },
    },
    {
      position: [-1, 0, 1],
      link: "/experience/je.png",
      href: "#je",
      hexaref:"hexaje",
      size: { x: 10, y: 9 },
    },
  ];
  const certImgData = [
    {
      position: [0, 0, 0],
      link: "/certs/aws_architect.png",
      href: "https://www.credly.com/badges/09e343ec-e6b3-458d-bd9f-373a964e5f24?source=linked_in_profile",
      hexaref:"hexaaws_archi",
      size: { x: 8.7, y: 10 },
    },
  ];

  const viewBox2 = "-40 -10 60 30";
  return (
    <div>
      <Layout iconName="experience" pageWidth="1700px">
        <PageHeading headingText="Experience" subHeadingText="" />
        <ExpeWrapper>
          <ExpeSectionWrapper style={{display:"block"}}>
            <CenteredH1> Schools </CenteredH1>
            <HexagonGrid7
              height={hexSize / 2}
              width={hexSize}
              viewBox={viewBox2}
              imgDatas={schoolImgDatas}
            />
          </ExpeSectionWrapper>
          <ExpeSectionWrapper>
            <CenteredH1> Previous positions</CenteredH1>
            <HexagonGrid7
              height={hexSize}
              width={hexSize}
              name={"job"}
              imgDatas={jobImgDatas}
              viewBox={"-30 -30 60 60"}
            />
          </ExpeSectionWrapper>
          <ExpeSectionWrapper>
            <CenteredH1> Certifications</CenteredH1>
            <HexagonGrid7
              height={hexSize / 2}
              width={hexSize}
              viewBox={"-30 -10 60 20"}
              imgDatas={certImgData}
              flat={false}
            />
          </ExpeSectionWrapper>
          <ExpeSectionWrapper>
            <CenteredH1> Packages </CenteredH1>
            <HexagonGrid7
              height={hexSize / 2}
              width={hexSize}
              viewBox={viewBox2}
              imgDatas={packageImgDatas}
            />
          </ExpeSectionWrapper>
        </ExpeWrapper>
        <CenteredH1>Timeline </CenteredH1>
        <TimeLine></TimeLine>
      </Layout>
    </div>
  );
};
export default AboutPage;
